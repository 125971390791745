import React, { Component } from "react";
import { graphql } from "gatsby";

import { Layout, Loader } from "components";

const Page = ({ data, location }) => {
  const { post } = data;
  const { content, title, acfLayout, seo, pageSettings } = post;

  return (
    <Layout pageTitle={title} seo={seo} pageSettings={pageSettings}>
      <div className="page">
        {acfLayout?.layout?.length > 0 ? (
          <Loader
            data={acfLayout.layout.map(({ ...layout }) => ({
              ...layout,
            }))}
            location={location}
          />
        ) : (
          content && (
            <div className="layout">
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          )
        )}
      </div>
    </Layout>
  );
};

export default Page;

export const PageQueryById = graphql`
  query PageById($id: String!) {
    # selecting the current page by id
    post: wpPage(id: { eq: $id }) {
      id
      content
      title
      isFrontPage
      ...SEOPageQuery
      pageSettings {
        removeFooterNavigation
        removeHeaderNavigation
      }
      acfLayout {
        fieldGroupName
        layout {
          ...HeroQuery
          ...ImageAndContentBlockQuery
          ...TextContentBlockQuery
          ...ContainedImageBlockQuery
          ...CardBlockQuery
          ...StaffProfilesQuery
          ...TabbedContentBlockQuery
          ...FlexibleBlockQuery
          ...FormBlockQuery
          ...BlogPostListQuery
          ...FeaturedPostsQuery
          ...GlobalImageAndTextQuery
        }
      }
    }
  }
`;
